<template>
  <button
    :aria-label="title"
    class="link uppercase font-bold w-full text-left lg:w-auto tabs-item-button border-t lg:border-t-0 lg:px-20 flex justify-between items-center"
    @click="context?.onToggle(props.id)"
  >
    <span class="inline-block py-30" :data-tabs-item="id">{{ title }}</span>
    <SpriteSymbol
      :name="isActive ? 'minus_mobile' : 'plus_mobile'"
      class="w-[14px] h-[14px] lg:hidden"
    />
  </button>
  <Collapse
    @transitionend="onTransitionEnd"
    v-if="isMobileOrTablet"
    :when="isActive"
    class="tabs-item-content"
  >
    <div>
      <slot></slot>
    </div>
  </Collapse>
  <Transition v-else name="tabs-fade">
    <div
      v-show="isActive"
      class="tabs-item-content"
      :class="{
        'is-active-desktop':
          first && !isActive && context?.active.value.length === 0,
      }"
    >
      <div>
        <slot></slot>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import type { TabContext } from './../index.vue'
import { Collapse } from 'vue-collapsed'

const { isMobileOrTablet } = useViewport()

const props = defineProps<{
  id: string
  title: string
  first?: boolean
}>()

/**
 * Reset the height to initial after the collapse transition.
 *
 * This allows the content inside the tab item to change its height after the
 * tab is open, e.g. when there is another collapse element nested in the tree.
 */
function onTransitionEnd(e: TransitionEvent) {
  const target = e.target
  if (target && target instanceof HTMLElement && isActive.value) {
    target.style.height = 'initial'
  }
}

defineEmits(['open'])

const context = inject<TabContext>('tabContext')

const isActive = computed(
  () => context?.active.value.includes(props.id) || false,
)
</script>

<style lang="postcss">
.tabs-item-content {
  > div {
    @apply pb-25 lg:pt-40;
  }
  @apply lg:border-t;
  @screen lg {
    grid-column: 1 / -1;
    grid-row: 2;

    &.is-active-desktop {
      display: block !important;
    }
  }
}

.tabs-item-button {
  border-top-width: 0.75px;
  @screen lg {
    grid-row: 1;
    border: none;
  }
}

.tabs-fade-enter-active,
.tabs-fade-leave-active {
  transition: 0.2s cubic-bezier(0.43, 0.72, 0.56, 0.99);
}
.tabs-fade-enter-from,
.tabs-fade-leave-to {
  opacity: 0;
}
</style>
